
import { Vue } from 'vue-class-component'
import axios from '@/plugins/axios'
// import { ElMessageBox } from 'element-plus'
import listPlatform from '../../../store/platform'

interface Result {
  data: any
  message: string
  name: string
  type: string
  status: boolean
}

export default class PlatformPage extends Vue {
  name = 'Platform Keys'
  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }
  listData: any = []
  formData: any = {
    id: null,
    name: '',
    type: '',
    isToken: false,
    price: 0,
    discountlv1: 0,
    discountlv2: 0,
    image: '',
    options: []
  }
  options: any = {
    name: '',
    key: '',
    note: ''
  }
  listEmptyPrice: any
  data: any = new FormData()
  titleModal = ''
  dialogPlatform = false
  controls = false
  url = `${process.env.VUE_APP_BASE_URL}/`
  showDeletePlatform = false
  deletePlatformId = ''
  deletePlatformIndex = 0

  async created() {
    this.getList()
    this.listEmptyPrice = listPlatform.platforms.map((item) => {
      return {
        target: item,
        price: 0
      }
    })
  }

  async getList() {
    let res: Result
    await this.$store.dispatch('setLoading', true, { root: true })
    res = await axios.get('admin/platform', {
      headers: this.headers
    })
    if (res) {
      this.listData = res.data
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }

  handleDelete(id: string, index: number) {
    this.deletePlatformId = id
    this.deletePlatformIndex = index
    this.showDeletePlatform = true
    // let res: Result
    // ElMessageBox.confirm('Do you want to delete platform?', 'Warning', {
    //   confirmButtonText: 'OK',
    //   cancelButtonText: 'Cancel',
    //   type: 'warning'
    // }).then(async () => {
    //   res = await axios.post(
    //     'admin/platform/delete',
    //     { id: id },
    //     {
    //       headers: this.headers
    //     }
    //   )
    //   if (res) {
    //     this.$message.success(res.message)
    //     this.listData.splice(index, 1)
    //   }
    // })
  }
  async handleDeletePlatform() {
    let res: Result

    res = await axios.post(
      'admin/platform/delete',
      { id: this.deletePlatformId },
      {
        headers: this.headers
      }
    )
    if (res) {
      this.$message.success(res.message)
      this.listData.splice(this.deletePlatformIndex, 1)
    }
  }
  handleRemoveOption(index: any) {
    this.formData.options.splice(index, 1)
  }
  async dialogOpenModal(row: any) {
    this.data = new FormData()
    if (row) {
      this.titleModal = 'Update Platform'
      this.formData.id = row._id
      this.formData.name = row.name
      this.formData.type = row.type
      this.formData.isToken = row.isToken
      this.formData.options = row.options
      this.formData.price = row.price
      this.formData.discountlv1 = row.discountlv1
      this.formData.discountlv2 = row.discountlv2
      this.formData.image = this.url + row.image
      this.dialogPlatform = true
      let ids = new Set(row.listPrice.map((d: any) => d.target))
      let merged = [
        ...row.listPrice,
        ...this.listEmptyPrice.filter(
          (d: any) =>
            !ids.has(d.target) && d.target !== row.name.replace(/\s/g, '')
        )
      ]
      this.formData.listPrice = merged
    } else {
      this.formData.id = null
      this.formData.name = ''
      this.formData.type = ''
      this.formData.isToken = false
      this.formData.image = ''
      this.formData.price = 0
      this.formData.discountlv1 = 0
      this.formData.discountlv2 = 0
      this.formData.options = []
      this.titleModal = 'Add Platform'
      this.dialogPlatform = true
      this.formData.listPrice = this.listEmptyPrice
    }
  }
  async handleUploadFileChange(e: any) {
    this.formData.image = ''
    const input = e.target
    if (input.files[0]) {
      const file = new FormData()
      file.append('file', input.files[0], input.files[0].name)
      this.formData.image = URL.createObjectURL(e.target.files[0])
      let res: Result
      res = await axios.post('logo', file, {
        headers: {
          'token-user': localStorage.getItem('tokenUser'),
          'Content-Type': 'multipart/form-data'
        }
      })
      if (res && res.status) {
        this.data.image = res.message
      }
    }
  }
  handleAddOption() {
    if (this.formData.options) {
      let option: any
      for (option of this.formData.options) {
        if (option.key == this.options.key) {
          return this.$message.warning(
            'Option key ' + this.options.key + ' exist',
            'Error message!!!'
          )
        }
      }
      this.formData.options.push({
        name: this.options.name,
        key: this.options.key,
        note: this.options.note
      })
    } else {
      this.formData.options.push({
        name: this.options.name,
        key: this.options.key,
        note: this.options.note
      })
    }
    this.options.name = ''
    this.options.key = ''
    this.options.note = ''
  }

  handleCancel() {
    this.showDeletePlatform = false
    this.deletePlatformId = ''
    this.deletePlatformIndex = 0
  }

  async handleSave() {
    if (this.formData.name == '') {
      this.$message.warning('Please input the name !')
      return
    }
    // if(this.formData.options.length <= 0) {
    //     this.$message.warning("Please input the option !")
    //     return
    // }
    if (!this.formData.image) {
      this.$message.warning('Please select the image !')
      return
    }

    if (this.formData.id) {
      this.data.id = this.formData.id
      this.formData.listPrice.forEach((item: any) => {
        if (!item.hub) {
          item.hub = this.formData.name.replace(/\s/g, '')
        }
      })
    }
    this.data.name = this.formData.name
    this.data.type = this.formData.type
    this.data.options = JSON.stringify(this.formData.options)
    this.data.price = this.formData.price
    this.data.discountlv1 = this.formData.discountlv1
    this.data.discountlv2 = this.formData.discountlv2
    this.data.isToken = this.formData.isToken
    this.data.listPrice = this.formData.listPrice
    let res: Result

    res = await axios.post('admin/platform', JSON.stringify(this.data), {
      headers: {
        'token-user': localStorage.getItem('tokenUser'),
        'Content-Type': 'multipart/form-data'
      }
    })
    if (res && res.status) {
      this.$message.success(res.message)
      this.getList()
      this.dialogPlatform = false
    } else {
      this.$message.warning(res.message)
    }
  }
}
