const platforms = [
  'ChannelAdvisor',
  'BigCommerce',
  'Esty',
  'Magento2',
  'HyperMule',
  'Linnworks',
  'Peoplevox',
  'Shopify',
  'Tiktok',
  'Woocommerce',
  'Alphabroder',
  'QuickBooks',
  'Prestashop',
  'VisualSoft',
  'Lightspeed',
  'Brightpearl',
  'Odoo',
  'Xentral',
  'Essendant',
  'lightspeedX',
  'Netsuite',
  'Xero',
  'Portica',
  'Mirakl',
  'ActiveAnt',
  'Mebel',
  'Aims360',
  'Cin7',
  'SapB1',
  'Dynamics365',
  'Teapplix',
  'Mirakl',
  'QuickBookDesktop',
  'Convictional',
  'warner',
  'Quivo',
  'CommerceHub',
  'Linkweld',
  'Myflex',
  'Dynamics AX EDI',
  'Dynamicsfo',
  'SapB1FileExport',
  'ChannelAdvisorFileImport',
  'Magento2SAAS',
]
interface simpleInt {
  hub: string
  target: string
  price: number
}
const list: Array<simpleInt> = []
// platforms.forEach((platformFrom: any) => {
//   platforms.forEach((platformTo: any) => {
//     if (platformFrom != platformTo) {
//       list.push({ hub: platformFrom, target: platformTo, price: 0 })
//     }
//   })
// })

platforms.forEach((platform: any) => {
  list.push({ hub: platform, target: platform, price: 0 })
})

export default { list, platforms }
